

export const topFiveByActivityTypeOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  indexAxis: 'y',
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      anchor: 'end',
      align: 'end',
      formatter: (value: any) => value || '',
      color: '#118f84',
      font: {
        weight: 'bold',
        size: 14
      }
    }
  },
  scales: {
    x: {
      beginAtZero: true,
    },
    y: {
      ticks: {
        crossAlign: 'far',
      },
    }
  }
}

export const topFiveByInvestmentSourceOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle',
        boxWidth: 9,
        boxHeight: 9,
        font: {
          size: 9,
        }
      }
    },
    datalabels: {
      color: '#FFFFFF',
      anchor: 'center',
      align: 'center',
      clip: true,
      display: (context: any) => {
        const value = context.dataset.data[context.dataIndex];
        const barHeight = context.chart.scales.y.getPixelForValue(0) - context.chart.scales.y.getPixelForValue(value);
        return barHeight >= 20; // Show datalabel only if the height of the portion is enough
      },
      formatter: (value: any) => value
    }
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
        drawTicks: false,
      },
      ticks: {
        font: {
          size: 9
        }
      }
    },
    y: {
      stacked: true,
      beginAtZero: true,
      grid: {
        color: 'rgba(0, 0, 0, 0.2)',
        tickBorderDash: [2, 2],
        drawTicks: false,
      },
      border: {
        dash: [2, 2]
      },
      ticks: {
        display: true,
      }
    }
  }
}

export const topTenPSDOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  indexAxis: 'y',
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      anchor: 'end',
      align: 'end',
      formatter: (value: any) => value || '',
      color: '#118f84',
      font: {
        weight: 'bold',
        size: 14
      }
    }
  },
  scales: {
    x: {
      beginAtZero: true,
      display: false,
      border: {
        display: false
      },
    },
    y: {
      ticks: {
        crossAlign: 'far',
      },
      border: {
        display: false
      },
    }
  }
}

