import React, { FC, useEffect, useState } from 'react'
import { BasicStatsItem } from '../index.styles';
import { Text } from '../../text.component';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartAnnotations from 'chartjs-plugin-annotation'
import { ILandFund, ILargeLandowners } from '../../../interfaces/snp.interface';

ChartJS.register(ChartAnnotations);


const table_header = [
  'Наименование компании',
  'Всего закреплено земель, га',
  'Пашня',
  'Пастбища',
  'Фактическое использование за 3 года',
  'Пашня',
  'Пастбища',
]


interface IProps {
  data: ILandFund
}

const LandFundTab: FC<IProps> = ({ data }) => {
  const [largeLandowners, setLargeLandowners] = useState<ILargeLandowners[]>([]);
  const [landFundItems, setLandFundItems] = useState<any>(null);

  const buildData = () => {
    if (data) {
      setLargeLandowners(data.largeLandowners || []);

      const { settlementLand, agricultural_pasture, agricultural_arable, agricultural_farmEnterprises, otherLand, reserve_arable, reserve_pasture } = data.landFundItems || {};
      const reserve_total = +(reserve_arable || 0) + +(reserve_pasture || 0);
      const agricultural_total = +(agricultural_arable || 0) + +(agricultural_pasture || 0);

      setLandFundItems({
        data: {
          labels: ['Земли населенного пункта, га', 'Земли сельхоз. назначения, га', 'Земли запаса, га', 'Другие, га'],
          datasets: [
            {
              label: 'Пастбища',
              data: [+(settlementLand || 0), +(agricultural_pasture || 0), +(reserve_pasture || 0), +(otherLand || 0)],
              backgroundColor: '#118F84',
              borderRadius: 5,
              barThickness: 76,
            },
            {
              label: 'Пашни',
              data: [null, +(agricultural_arable || 0), +(reserve_arable || 0), null],
              backgroundColor: '#92D050',
              borderRadius: 5,
              barThickness: 76,
            },
            {
              label: 'total',
              data: [0, 0, 0, 0],
              backgroundColor: 'transparent',
              barThickness: 76,
            },
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false,
              },
              ticks: {
                color: '#818689',
                font: {
                  size: 12,
                  weight: 400,
                },
              },
            },
            y: {
              stacked: true,
              beginAtZero: true,
              grid: {
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
              max: function () {
                const values = [+(settlementLand || 0), +(agricultural_total || 0), +(reserve_total || 0), +(otherLand || 0),];
                return Math.max(...values) * 1.25;
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (context: any) => {
                  const label = context.dataset.label || '';
                  const value = context.raw;
                  const index = context.dataIndex;

                  if (index === 1 && label === 'Пашни') {
                    return `Пашни: ${value.toLocaleString()}`;
                  } else if (index === 2 && label === 'Пашни') {
                    return `Пахотно-пригодные земли: ${value.toLocaleString()}`;
                  } else if (index === 0 || index === 3) {
                    return value.toLocaleString();
                  }

                  return `${label}: ${value.toLocaleString()}`;
                },
              },
            },
            datalabels: {
              display: (context: any) => {
                const index = context.dataIndex;
                const isTotal = context.dataset.label === 'total';

                const pastureValue = context.chart.data.datasets[0].data[index];
                const arableValue = context.chart.data.datasets[1].data[index];

                return isTotal || (pastureValue !== null && arableValue !== null);
              },
              formatter: (value: any, context: any) => {
                if (context.dataset.label === 'total') {
                  const pastureValue = context.chart.data.datasets[0].data[context.dataIndex] || 0;
                  const arableValue = context.chart.data.datasets[1].data[context.dataIndex] || 0;

                  const total = pastureValue + arableValue;

                  return total.toLocaleString();
                }

                return value !== null ? value.toLocaleString() : '';
              },
              align: (context: any) => context.dataset.label === 'total' ? 'end' : 'center',
              anchor: (context: any) => context.dataset.label === 'total' ? 'end' : 'center',
              font: {
                size: 11,
                weight: 'bold',
              },
              color: (context: any) => context.dataset.label === 'total' ? '#000' : '#fff',
            },
            annotation: {
              annotations: {
                label1: {
                  type: 'label',
                  xValue: 1.1,
                  yValue: (+(agricultural_arable || 0) + +(agricultural_pasture || 0)) * 1.05,
                  xAdjust: 100,
                  yAdjust: 20,
                  content: [`${+(agricultural_farmEnterprises || 0)} сельхоз.`, 'предприятий'],
                  callout: {
                    display: true,
                    side: 0,
                    position: 'bottom',
                    start: 0
                  },
                  textAlign: 'start',
                }
              }
            }
          }
        }
      });
    }
  }

  useEffect(() => {
    buildData()
  }, [data])

  return (
    <div className='grid c-1 screening land-fund'>
      <BasicStatsItem>
        <div className="title">
          <div className="left">
            <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>Крупные землевладельцы района</Text>
          </div>
        </div>
        <div className="body">
          <table className='screening-table'>
            <thead>
              <tr>
                {table_header.map((item, index) => (
                  <th key={index}>
                    <Text fontSize='0.75rem' color='#7C7E7C' fontWeight={400}>
                      {item}
                    </Text>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {largeLandowners && largeLandowners.map((item) => (
                <tr key={item.name}>
                  <td>
                    <Text color='#000105' fontWeight={400} fontSize='0.875rem'>{item.name}</Text>
                  </td>
                  <td>
                    <Text color='#118F84' fontWeight={600} fontSize='0.875rem'>{item.total_land?.toLocaleString()}</Text>
                  </td>
                  <td>
                    <Text color='#7C7E7C' fontWeight={600} fontSize='0.875rem'>{item.total_arable?.toLocaleString()}</Text>
                  </td>
                  <td>
                    <Text color='#118F84' fontWeight={600} fontSize='0.875rem'>{item.total_pasture?.toLocaleString()}</Text>
                  </td>
                  <td>
                    <Text color='#118F84' fontWeight={600} fontSize='0.875rem'>{item.fact_land?.toLocaleString()}</Text>
                  </td>
                  <td>
                    <Text color='#7C7E7C' fontWeight={600} fontSize='0.875rem'>{item.fact_arable?.toLocaleString()}</Text>
                  </td>
                  <td>
                    <Text color='#118F84' fontWeight={600} fontSize='0.875rem'>{item.fact_pasture?.toLocaleString()}</Text>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

        </div>
      </BasicStatsItem>

      {landFundItems && landFundItems.data && <BasicStatsItem>
        <div className="title">
          <div className="left">
            <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>Земельный фонд</Text>
          </div>
        </div>
        <div className="body" style={{ maxHeight: '20rem', paddingBottom: '1rem' }}>
          {landFundItems && landFundItems.data && <Bar data={landFundItems.data} options={landFundItems.options} plugins={[ChartDataLabels, ChartAnnotations]} />}
        </div>
      </BasicStatsItem>}
    </div>
  )
}

export default LandFundTab