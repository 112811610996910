import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Tabs from '../../components/admin-page/tabs.component';
import GeneralInfo from '../../components/admin-page/general-info.component';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.9375rem;
`;

const Content = styled.div`
`

const SelectedSnpPage = () => {
  const navigate = useNavigate();
  const { kato } = useParams();

  const [selectedTab, setSelectedTab] = useState<any>(null);
  const [content, setContent] = useState(<GeneralInfo />);

  const handleSelectTab = (tab: string) => {
    setSelectedTab(tab);
    switch (tab) {
      case 'general_info':
        setContent(<GeneralInfo />);
        navigate(`/admin/${kato}`);
        break;
      case 'roads-condition':
        navigate(`/admin/${kato}/roads-condition`);
        break;
      case 'self-sufficiency':
        navigate(`/admin/${kato}/self-sufficiency`);
        break;
      case 'infrastructure':
        navigate(`/admin/${kato}/infrastructure`);
        break;
      case 'population-dynamic':
        navigate(`/admin/${kato}/population-dynamic`);
        break;
      case 'ser':
        navigate(`/admin/${kato}/ser`);
        break;
      case 'tax':
        navigate(`/admin/${kato}/tax`);
        break;
      case 'budget':
        navigate(`/admin/${kato}/budget`);
        break;
      case 'screening':
        navigate(`/admin/${kato}/screening`);
        break;
      default:
        navigate(`/admin/${kato}`);
        break;
    }
  }

  useEffect(() => {
    const { pathname } = window.location;

    let selected = null;

    pathname.split('/').forEach((item) => {
      if (
        [
          'snp_agriculture_producers', 'snp_improvement', 'snp_investment',
          'roads-condition', 'self-sufficiency', 'ser', 'infrastructure',
          'population-dynamic', 'tax', 'budget', 'screening'
        ].includes(item)) {


        setSelectedTab(item);
        selected = item;
      }
    })

    if (!selected) {
      setContent(<GeneralInfo />);
      setSelectedTab('general_info')
    }

  }, [window.location.pathname])

  return (
    <Wrapper>
      {kato && <Tabs kato={kato} selectedTab={selectedTab} onSelect={handleSelectTab} />}
      {!['general_info'].includes(selectedTab) ? <Outlet /> : <Content>{content}</Content>}
    </Wrapper>
  )
}

export default SelectedSnpPage