import React, { useState } from 'react'
import styled from 'styled-components'
import { Text } from '../../text.component';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BasicStatsItem } from '../index.styles';
import { topTenPSDOptions } from './chart-options';

const TopTenPsdContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1.25rem;
  gap: 0.625rem;

  > div {
    max-width: 99% 
  }
`;

const Filters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
`;

const FilterItem = styled.div`
  display: flex;
  width: 100%;
  gap: 0.625rem;
  flex-direction: column;

  select {
    border: 1px solid #DBDCE0;
    border-radius: 5px;
    height: 2rem;
    text-indent: 0.625rem;
    font-size: 0.75rem;
    &:focus {
      outline: none;
    }
  }

  .dates {
    display: flex;
    gap: 0.3125rem;
    align-items: center;

    input {
      border: 1px solid #DBDCE0;
      border-radius: 5px;
      height: 2rem;
      text-indent: 0.625rem;
      flex: 1;
      font-size: 0.75rem;

      &:focus {
        outline: none;
      }
    }
  }
`;

const options = ['Все районы', 'текущий район']
const topTenCustomersData = {
  labels: ['Клиент 1', 'Клиент 2', 'Клиент 3', 'Клиент 4', 'Клиент 5', 'Клиент 6', 'Клиент 7', 'Клиент 8', 'Клиент 9', 'Клиент 10'],
  datasets: [
    {
      label: 'Количество ПСД',
      data: [220, 58, 46, 27, 18, 15, 12, 10, 8, 6],
      backgroundColor: "#118F84",
      barThickness: 9,
      borderRadius: 2,
    }
  ]
}

const byObjectTypeData = {
  labels: ['Дорога', 'МСБ', 'Другое', 'Здание', 'Школа', 'Детский сад', 'Парк', 'Спорткомплекс', 'Театр', 'Кинотеатр', 'Музей', 'Библиотека', 'Кинотеатр', 'Музей', 'Библиотека'],
  datasets: [
    {
      label: 'Количество ПСД',
      data: [108, 58, 46, 27, 18, 15, 12, 10, 8, 6, 5, 4, 3, 2, 1],
      backgroundColor: "#118F84",
      barThickness: 9,
      borderRadius: 2,
    }
  ]
}

interface IFilters {
  projectName: string,
  customer: string,
  objectLocation: string,
  examLocation: string,
  BIN: string,
  fundSource: string,
  designers: string,
  conclusionNumber: string,
  startDate: string,
  endDate: string
}

const filterNames: (keyof IFilters)[] = ['projectName', 'customer', 'objectLocation', 'examLocation', 'BIN', 'fundSource', 'designers', 'conclusionNumber']

const getText = (str: string) => {
  return <Text fontWeight={400} color="#202224" fontSize='0.75rem'>{str}</Text>
}

const TopTenPsd = () => {
  const [filters, setFilters] = useState<IFilters>({
    projectName: '',
    customer: '',
    objectLocation: '',
    examLocation: '',
    BIN: '',
    fundSource: '',
    designers: '',
    conclusionNumber: '',
    startDate: '',
    endDate: ''
  })

  return (
    <TopTenPsdContainer>
      <Filters>
        <FilterItem>
          {getText('период')}
          <div className="dates">
            <input type="date" value={filters['startDate']} onChange={(e) => setFilters({ ...filters, startDate: e.target.value })} />
            -
            <input type="date" value={filters['endDate']} onChange={(e) => setFilters({ ...filters, endDate: e.target.value })} />
          </div>
        </FilterItem>

        {
          filterNames.map((filterName, index) => (
            <FilterItem key={index}>
              {getText(filterName)}
              <select
                value={filters[filterName]}
                onChange={(e) => setFilters({ ...filters, [filterName]: e.target.value })}
              >
                {
                  options.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                  ))
                }
              </select>
            </FilterItem>
          ))
        }
      </Filters>

      <BasicStatsItem>
        <div className="title">
          <div className="left">
            <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>ТОП - 10 заказчиков ПСД</Text>
            <Text color='#818689' fontWeight={400} fontSize='0.75rem'>количество ПСД</Text>
          </div>
        </div>
        <div className="body">
          <Bar data={topTenCustomersData} options={topTenPSDOptions} plugins={[ChartDataLabels]} />
        </div>
      </BasicStatsItem>
      <BasicStatsItem>
        <div className="title">
          <div className="left">
            <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>ПСД по категории объекта</Text>
            <Text color='#818689' fontWeight={400} fontSize='0.75rem'>количество ПСД</Text>
          </div>
        </div>
        <div className="body">
          <Bar data={byObjectTypeData} options={topTenPSDOptions} plugins={[ChartDataLabels]} />
        </div>
      </BasicStatsItem>
    </TopTenPsdContainer>
  )
}

export default TopTenPsd