import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Footer, FooterButton, FormBlock, FormGroup, Wrapper } from '../../components/admin-page/admin-page.styles';
import { Form, Formik, Field } from 'formik';
import { AddField, ArrowDown, DarkArrowRight, GoUp, Save } from '../../assets/icons';
import { calculateRoadSharePercentage, checkVal, findIndexByKey, getLang, getObjectTotals, getRegionTotals, handleGoUp, isRegion, OBLAST_KATO, t, updateKatoAccessList } from '../../utils/helpers.utils';
import { toast, ToastContainer } from 'react-toastify';
import { getGeneralInfo } from '../../requests/snp.request';
import { IGeneralInfo, IRoadShare, IRoadShareItem, IRoadShareStats } from '../../interfaces/snp.interface';
import { FormBlockButton } from '../../components/insfrastructureProjects/infrastructure.styles';
import { updateFormPartial } from '../../requests/supervisor.request';

const roadFields = [
  {
    title: 'road-table.2023-result',
    conditions: [
      {
        name: 'road-table.good',
        fields: ['prevYearGoodConditionKm', 'prevYearGoodConditionPercentage',]
      },
      {
        name: 'road-table.satisfactory',
        fields: ['prevYearSatisfactoryConditionKm', 'prevYearSatisfactoryConditionPercentage',]
      },
      {
        name: 'road-table.not-satisfactory',
        fields: ['prevYearPoorConditionKm', 'prevYearPoorConditionPercentage',]
      },
    ]
  },
  {
    title: 'road-table.2024-result',
    conditions: [
      {
        name: 'road-table.good',
        fields: ['curYearGoodConditionKm', 'curYearGoodConditionPercentage',]
      },
      {
        name: 'road-table.satisfactory',
        fields: ['curYearSatisfactoryConditionKm', 'curYearSatisfactoryConditionPercentage',]
      },
      {
        name: 'road-table.not-satisfactory',
        fields: ['curYearPoorConditionKm', 'curYearPoorConditionPercentage',]
      },
    ]
  },
]

const roadRegionKeys = [
  'prevYearGoodConditionKm', 'prevYearGoodConditionPercentage', 'prevYearSatisfactoryConditionKm', 'prevYearSatisfactoryConditionPercentage',
  'prevYearPoorConditionKm', 'prevYearPoorConditionPercentage', 'curYearGoodConditionKm', 'curYearGoodConditionPercentage', 'curYearSatisfactoryConditionKm',
  'curYearSatisfactoryConditionPercentage', 'curYearPoorConditionKm', 'curYearPoorConditionPercentage'
]

const defaultRoad: IRoadShareItem = {
  index: '',
  roadName: '',
  totalKm: '',
  prevYearGoodConditionKm: '',
  prevYearGoodConditionPercentage: '',
  prevYearSatisfactoryConditionKm: '',
  prevYearSatisfactoryConditionPercentage: '',
  prevYearPoorConditionKm: '',
  prevYearPoorConditionPercentage: '',
  curYearGoodConditionKm: '',
  curYearGoodConditionPercentage: '',
  curYearSatisfactoryConditionKm: '',
  curYearSatisfactoryConditionPercentage: '',
  curYearPoorConditionKm: '',
  curYearPoorConditionPercentage: '',
  ongoingProjects: ''
}

const RoadsConditionPage = () => {
  const { i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { kato } = useParams();

  const formikRef = useRef<any>(null);
  const wrapperRef = useRef<any>(null);

  const [tree, setTree] = useState<any[]>([]);
  const [region, setRegion] = useState<number>(0);
  const [snp, setSnp] = useState<number>(0);
  const [katoAccessList, setKatoAccessList] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isKatoRegion, setIsKatoRegion] = useState(false);
  const [formData, setFormData] = useState<IGeneralInfo>({} as IGeneralInfo);
  const [totalRoadShareStats, setTotalRoadShareStats] = useState<IRoadShare>({} as IRoadShare);
  const [errors, setErrors] = useState<any>({});
  const [openObjects, setOpenObjects] = useState<{ [key: string]: boolean }>({});
  const [isAdding, setIsAdding] = useState(false);
  const [newObject, setNewObject] = useState<IRoadShareStats>({
    name: '',
    roadsList: []
  });
  const [basicStats, setBasicStats] = useState<any>({});


  const validateForm = (data: any) => {
    setErrors({});
    let res = true;
    const roadData = data.basicStats.filter((item: any) => item.type === 'ROAD')[0].additionalInfo

    if (checkVal(roadData.good) || checkVal(roadData.satisfactory) || checkVal(roadData['not-satisfactory'])) {
      if (!checkVal(roadData.good) || !checkVal(roadData.satisfactory) || !checkVal(roadData['not-satisfactory'])) {
        setErrors((prev: any) => ({ ...prev, 'ROAD-NONE': true }))
        toast.error(t(`errors.ROAD-NONE`, language));
        return false;
      }
    }

    return res;
  }

  const handleSubmitForm = (values: any) => {
    setErrors({});

    const data = {
      basicStats,
      totalRoadShareStats: formData.totalRoadShareStats,
      status: values.status

    }

    if (kato && validateForm(data)) {
      updateFormPartial(kato, data)
        .then(() => {
          toast.success(t('toast.save_success'))
          loadForm();
        })
        .catch(() => toast.error('Ошибка при сохранении'));
    }
  }

  const handleRegionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setRegion(+e.target.value);
    kato && isRegion(+kato) && navigate(`/admin/${e.target.value}/roads-condition`)
  }

  const handleSnpChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSnp(+e.target.value)
    navigate(`/admin/${e.target.value}`)
  }

  const loadForm = useCallback(() => {
    const val = kato ? kato : snp
    val && getGeneralInfo(+val).then((res: any) => {
      setFormData(res as IGeneralInfo);
      setTotalRoadShareStats(res.totalRoadShareStats as IRoadShare);
      setBasicStats(res.basicStats);

      const objects = res.totalRoadShareStats?.roadShareStats?.reduce((acc: any, region: IRoadShareStats) => {
        acc[region.name] = false;
        return acc;
      }, {});
      setOpenObjects(objects);

      setIsLoading(false);
    });
  }, [region, snp, kato])

  const handleOpenObject = (value: string) => {
    setOpenObjects({ ...openObjects, [value]: !openObjects[value] });
  }

  const addRoad = () => {
    setNewObject({
      ...newObject,
      roadsList: [...newObject.roadsList, defaultRoad]
    });
  }

  const handleAddObject = () => {
    const objectToAdd: IRoadShareStats = {
      name: newObject.name,
      roadsList: newObject.roadsList,
      totalKm: getObjectTotals(newObject.roadsList, 'totalKm') || '',
      totalPrevYearGoodConditionKm: getObjectTotals(newObject.roadsList, 'prevYearGoodConditionKm') || '',
      totalPrevYearGoodConditionPercentage: getObjectTotals(newObject.roadsList, 'prevYearGoodConditionPercentage') || '',
      totalPrevYearSatisfactoryConditionKm: getObjectTotals(newObject.roadsList, 'prevYearSatisfactoryConditionKm') || '',
      totalPrevYearSatisfactoryConditionPercentage: getObjectTotals(newObject.roadsList, 'prevYearSatisfactoryConditionPercentage') || '',
      totalPrevYearPoorConditionKm: getObjectTotals(newObject.roadsList, 'prevYearPoorConditionKm') || '',
      totalPrevYearPoorConditionPercentage: getObjectTotals(newObject.roadsList, 'prevYearPoorConditionPercentage') || '',
      totalCurYearGoodConditionKm: getObjectTotals(newObject.roadsList, 'curYearGoodConditionKm') || '',
      totalCurYearGoodConditionPercentage: getObjectTotals(newObject.roadsList, 'curYearGoodConditionPercentage') || '',
      totalCurYearSatisfactoryConditionKm: getObjectTotals(newObject.roadsList, 'curYearSatisfactoryConditionKm') || '',
      totalCurYearSatisfactoryConditionPercentage: getObjectTotals(newObject.roadsList, 'curYearSatisfactoryConditionPercentage') || '',
      totalCurYearPoorConditionKm: getObjectTotals(newObject.roadsList, 'curYearPoorConditionKm') || '',
      totalCurYearPoorConditionPercentage: getObjectTotals(newObject.roadsList, 'curYearPoorConditionPercentage') || '',
    }

    const updated = {
      ...formData,
      totalRoadShareStats: {
        ...totalRoadShareStats,
        roadShareStats: [...totalRoadShareStats.roadShareStats, objectToAdd]
      },
    }
    setTotalRoadShareStats(updated.totalRoadShareStats);
    setIsAdding(false);
    setNewObject({ name: '', roadsList: [] });
    setFormData(updated);
  }

  const renderSelects = (lang: 'Ru' | 'Kz' = 'Ru') => {
    return (
      kato && +kato !== OBLAST_KATO && <div className="grid-item">
        <FormGroup>
          <label htmlFor="region">{t(`form.region.name`, lang)}</label>
          <Field as="select" value={region} onChange={handleRegionChange} disabled={lang.toLowerCase() !== language}>
            {tree.map((item) => <option key={item.kato} value={item.kato}>{item[`name${getLang()}`]}</option>)}
          </Field>
        </FormGroup>

        {!isKatoRegion && <FormGroup>
          <label htmlFor="snp">{t(`form.district.name`, lang)}</label>
          <Field as="select" value={snp} onChange={handleSnpChange} disabled={lang.toLowerCase() !== language}>
            {tree.find(item => +item.kato === +region)?.children.map((item: any) => <option key={item.kato} value={item.kato}>{item[`name${getLang()}`]}</option>)}
          </Field>
        </FormGroup>}
      </div>
    )
  }

  const renderFields = (lang: 'Ru' | 'Kz', setFieldValue: any) => {
    return <div className="grid-item">
      {
        basicStats && <FormBlock id="basicStats" type='white'>
          <div className="title bold">{t(`new-fields.ROAD`, lang)}</div>
          <FormBlock type='white' style={{ marginBottom: 0 }}>
            {
              ['good', 'satisfactory', 'not-satisfactory'].map((key) => (
                <FormGroup>
                  <label htmlFor={`basicStats[${findIndexByKey(`ROAD-NONE`, basicStats)}].additionalInfo.${key}`}>
                    {t(`road-table.${key}`, lang)}, %
                  </label>
                  <Field
                    name={`basicStats[${findIndexByKey(`ROAD-NONE`, basicStats)}].additionalInfo.${key}`}
                    type="number"
                    as="input"
                    className={`${errors[`ROAD-NONE`] ? 'error' : ''}`}
                    value={basicStats[findIndexByKey(`ROAD-NONE`, basicStats)]?.additionalInfo?.[key] || ''}
                    onChange={(e: any) => {
                      const val = e.target.value;
                      if (val === '') {
                        setFieldValue(`basicStats[${findIndexByKey(`ROAD-NONE`, basicStats)}].additionalInfo.${key}`, '');
                        setBasicStats([
                          ...basicStats.map((item: any, i: number) => i === findIndexByKey(`ROAD-NONE`, basicStats) ? {
                            ...item,
                            additionalInfo: {
                              ...item.additionalInfo,
                              [key]: ''
                            }
                          } : item)
                        ])
                      } else {
                        setFieldValue(`basicStats[${findIndexByKey(`ROAD-NONE`, basicStats)}].additionalInfo.${key}`, Math.max(0, val).toString());
                        setBasicStats([
                          ...basicStats.map((item: any, i: number) => i === findIndexByKey(`ROAD-NONE`, basicStats) ? {
                            ...item,
                            additionalInfo: {
                              ...item.additionalInfo,
                              [key]: Math.max(0, val).toString()
                            }
                          } : item)
                        ])
                      }
                    }}
                  />
                </FormGroup>
              ))
            }
          </FormBlock>
        </FormBlock>
      }

      {
        totalRoadShareStats?.roadShareStats && totalRoadShareStats?.roadShareStats?.length > 0 && <FormBlock id="road" type='white'>
          <div className="title bold">{t(`roadShareStats.title`, lang)}</div>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <div className="label">{t(`road-totals.totalKm`)}</div>
            <div className="value">
              {getRegionTotals(totalRoadShareStats?.roadShareStats, 'totalKm')}
            </div>
          </div>
          <div className="road-values-grid">
            {
              roadRegionKeys.map((key: string) => (
                <div>
                  <div className="label">{t(`road-totals.${key}`)}</div>
                  <div className="value">
                    {getRegionTotals(totalRoadShareStats?.roadShareStats, key)}
                  </div>
                </div>
              ))
            }
          </div>
          {
            (totalRoadShareStats?.roadShareStats as IRoadShareStats[]).map((item: IRoadShareStats, index: number) => (
              <FormBlock type='white'>
                <div className="title bold with-chevron">
                  {item.name}
                  <span>
                    {openObjects[item.name] ? <ArrowDown onClick={() => handleOpenObject(item.name)} /> : <DarkArrowRight onClick={() => handleOpenObject(item.name)} />}
                  </span>
                </div>

                {
                  openObjects[item.name] === true && <>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                      <div className="label">{t(`road-totals.totalKm`)}</div>
                      <div className="value">
                        {getObjectTotals(item.roadsList, 'totalKm')}
                      </div>
                    </div>
                    <div className="road-values-grid">
                      {
                        roadRegionKeys.map((key: string) => (
                          <div>
                            <div className="label">{t(`road-totals.${key}`)}</div>
                            <div className="value">
                              {getObjectTotals(item.roadsList, key)}
                            </div>
                          </div>
                        ))
                      }
                    </div>
                    {
                      item.roadsList.map((road: IRoadShareItem, roadIndex: number) => (
                        <FormBlock type='white'>
                          <div className="title bold">{road.roadName}</div>
                          <>
                            <FormGroup>
                              <label htmlFor={`roadShareStats[${index}].roadsList[${roadIndex}].totalKm`}>
                                {t(`roadShareStats.totalKm`, lang)}
                              </label>
                              <Field
                                name={`roadShareStats[${index}].roadsList[${roadIndex}].totalKm`}
                                type="number"
                                as="input"
                                className={`${errors[`roadShareStats[${index}].roadsList[${roadIndex}].totalKm`] ? 'error' : ''}`}
                                value={road.totalKm || ''}
                                onChange={(e: any) => {
                                  const val = e.target.value;
                                  setFieldValue(`roadShareStats[${index}].roadsList[${roadIndex}].totalKm`, val);
                                  setTotalRoadShareStats({
                                    ...totalRoadShareStats,
                                    roadShareStats: totalRoadShareStats.roadShareStats.map((item: IRoadShareStats, i: number) => i === index ? {
                                      ...item,
                                      roadsList: item.roadsList.map((road: IRoadShareItem, j: number) => j === roadIndex ? {
                                        ...road,
                                        totalKm: val
                                      } : road)
                                    } : item)
                                  })
                                }}
                              />
                            </FormGroup>
                            <FormGroup>
                              <label htmlFor={`roadShareStats[${index}].roadsList[${roadIndex}].index`}>
                                {t(`roadShareStats.index`, lang)}
                              </label>
                              <Field
                                name={`roadShareStats[${index}].roadsList[${roadIndex}].index`}
                                as="input"
                                className={`${errors[`roadShareStats[${index}].roadsList[${roadIndex}].index`] ? 'error' : ''}`}
                                value={road.index || ''}
                                onChange={(e: any) => {
                                  const val = e.target.value;
                                  setFieldValue(`roadShareStats[${index}].roadsList[${roadIndex}].index`, val);
                                  setTotalRoadShareStats({
                                    ...totalRoadShareStats,
                                    roadShareStats: totalRoadShareStats.roadShareStats.map((item: IRoadShareStats, i: number) => i === index ? {
                                      ...item,
                                      roadsList: item.roadsList.map((road: IRoadShareItem, j: number) => j === roadIndex ? {
                                        ...road,
                                        index: val
                                      } : road)
                                    } : item)
                                  })
                                }}
                              />
                            </FormGroup>
                            {
                              roadFields.map((field: any, i: number) => (
                                <>
                                  <div className="title bold">{t(field.title)}</div>
                                  {
                                    field.conditions.map((condition: any, j: number) => (
                                      <>
                                        <div className="title bold">{t(condition.name)}</div>
                                        {
                                          condition.fields.map((key: any, k: number) => (
                                            <FormGroup>
                                              <label htmlFor={`roadShareStats[${index}].roadsList[${roadIndex}].${key}`}>
                                                {t(`roadShareStats.${key}`, lang)}
                                              </label>
                                              <Field
                                                name={`roadShareStats[${index}].roadsList[${roadIndex}].${key}`}
                                                type="number"
                                                as="input"
                                                min={0}
                                                className={`${errors[`roadShareStats[${index}].roadsList[${roadIndex}].${key}`] ? 'error' : ''}`}
                                                step="0.0001"
                                                value={
                                                  !key.includes('Percentage')
                                                    ? road[key as keyof IRoadShareItem] || ''
                                                    : calculateRoadSharePercentage(+road[`${key.replace('Percentage', 'Km')}` as keyof IRoadShareItem], +road.totalKm) || +road[`${key}Percentage` as keyof IRoadShareItem] || ''}
                                                disabled={key.includes('Percentage')}
                                                onChange={(e: any) => {
                                                  const val = +e.target.value >= 0 ? e.target.value : 0;
                                                  setFieldValue(`roadShareStats[${index}].roadsList[${roadIndex}].${key}`, val);
                                                  setTotalRoadShareStats({
                                                    ...totalRoadShareStats,
                                                    roadShareStats: totalRoadShareStats.roadShareStats.map((item: IRoadShareStats, i: number) => i === index ? {
                                                      ...item,
                                                      roadsList: item.roadsList.map((road: IRoadShareItem, j: number) => j === roadIndex ? {
                                                        ...road,
                                                        [key]: val
                                                      } : road)
                                                    } : item)
                                                  })
                                                }}
                                              />
                                            </FormGroup>
                                          ))
                                        }
                                      </>
                                    ))
                                  }
                                </>
                              ))
                            }

                            <FormGroup>
                              <label htmlFor={`roadShareStats[${index}].roadsList[${roadIndex}].ongoingProjects`}>
                                {t(`roadShareStats.ongoingProjects`, lang)}
                              </label>
                              <Field
                                name={`roadShareStats[${index}].roadsList[${roadIndex}].ongoingProjects`}
                                className={`${errors[`roadShareStats[${index}].roadsList[${roadIndex}].ongoingProjects`] ? 'error' : ''}`}
                                value={road['ongoingProjects'] || ''}
                                as="textarea"
                                onChange={(e: any) => {
                                  const val = e.target.value;
                                  setFieldValue(`roadShareStats[${index}].roadsList[${roadIndex}].ongoingProjects`, val);
                                  setTotalRoadShareStats({
                                    ...totalRoadShareStats,
                                    roadShareStats: totalRoadShareStats.roadShareStats.map((item: IRoadShareStats, i: number) => i === index ? {
                                      ...item,
                                      roadsList: item.roadsList.map((road: IRoadShareItem, j: number) => j === roadIndex ? {
                                        ...road,
                                        ongoingProjects: val
                                      } : road)
                                    } : item)
                                  })
                                }}
                              />
                            </FormGroup>
                          </>
                        </FormBlock>
                      ))
                    }
                  </>}
              </FormBlock>
            ))}

          {
            isAdding
            && <FormBlock type="white" >
              <FormGroup>
                <label className={'required'} htmlFor={`newStat.type${lang}`}>{t(`form.nameRu`, lang)}</label>

                <Field
                  name={`newObject.name`}
                  onChange={(e: any) => {
                    const val = e.target.value;
                    setNewObject({ ...newObject, name: val })
                  }}
                  className={`${errors[`newObject.name`]
                    ? 'error'
                    : ''
                    }`}
                  value={newObject.name}
                />
              </FormGroup>
              <FormGroup>
                <label htmlFor={`newObject.totalKm`}>
                  {t(`roadShareStats.totalKm`, lang)}
                </label>
                <Field
                  name={`newObject.totalKm`}
                  type="number"
                  as="input"
                  className={`${errors[`newObject.totalKm`] ? 'error' : ''}`}
                  value={newObject.totalKm || ''}
                  onChange={(e: any) => {
                    const val = e.target.value;
                    setFieldValue(`newObject.totalKm`, val);
                    setNewObject({
                      ...newObject,
                      totalKm: val
                    })
                  }}
                />
              </FormGroup>

              {newObject.roadsList && newObject.roadsList.map((road: any, index: number) => (
                <FormBlock type="white" >
                  <FormGroup>
                    <label htmlFor={`newObject.roadName`}>
                      {t(`roadShareStats.roadName`, lang)}
                    </label>
                    <Field
                      name={`newObject.roadName`}
                      as="input"
                      className={`${errors[`newObject.roadName`] ? 'error' : ''}`}
                      value={road.roadName || ''}
                      onChange={(e: any) => {
                        const val = e.target.value;
                        setFieldValue(`newObject.roadName`, val);
                        setNewObject({
                          ...newObject,
                          roadsList: newObject.roadsList.map((item: IRoadShareItem, i: number) => i === index ? {
                            ...item,
                            roadName: val
                          } : item)
                        })
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor={`newObject.index`}>
                      {t(`roadShareStats.index`, lang)}
                    </label>
                    <Field
                      name={`newObject.index`}
                      as="input"
                      className={`${errors[`newObject.index`] ? 'error' : ''}`}
                      value={road.index || ''}
                      onChange={(e: any) => {
                        const val = e.target.value;
                        setFieldValue(`newObject.index`, val);
                        setNewObject({
                          ...newObject,
                          roadsList: newObject.roadsList.map((item: IRoadShareItem, i: number) => i === index ? {
                            ...item,
                            index: val
                          } : item)
                        })
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor={`newObject.totalKm`}>
                      {t(`roadShareStats.totalKm`, lang)}
                    </label>
                    <Field
                      name={`newObject.totalKm`}
                      type="number"
                      as="input"
                      className={`${errors[`newObject.totalKm`] ? 'error' : ''}`}
                      value={road.totalKm || ''}
                      onChange={(e: any) => {
                        const val = e.target.value;
                        setFieldValue(`newObject.totalKm`, val);
                        setNewObject({
                          ...newObject,
                          roadsList: newObject.roadsList.map((item: IRoadShareItem, i: number) => i === index ? {
                            ...item,
                            totalKm: val
                          } : item)
                        })
                      }}
                    />
                  </FormGroup>
                  {
                    roadFields.map((field: any, i: number) => (
                      <>
                        <div className="title bold">{t(field.title)}</div>

                        {
                          field.conditions.map((condition: any, j: number) => (
                            <>
                              <div className="title bold">{t(condition.name)}</div>

                              {
                                condition.fields.map((key: any, k: number) => (
                                  <FormGroup>
                                    <label htmlFor={`newObject.${key}`}>
                                      {t(`roadShareStats.${key}`, lang)}
                                    </label>
                                    <Field
                                      name={`newObject.${key}`}
                                      type="number"
                                      as="input"
                                      min={0}
                                      className={`${errors[`newObject.${key}`] ? 'error' : ''}`}
                                      step="0.0001"
                                      value={
                                        !key.includes('Percentage')
                                          ? road[key as keyof IRoadShareItem] || ''
                                          : calculateRoadSharePercentage(+road[`${key.replace('Percentage', 'Km')}` as keyof IRoadShareItem], +newObject.totalKm!) || ''}
                                      disabled={key.includes('Percentage')}
                                      onChange={(e: any) => {
                                        const val = +e.target.value >= 0 ? e.target.value : 0;
                                        setFieldValue(`newObject.${key}`, val);
                                        setNewObject({
                                          ...newObject,
                                          roadsList: newObject.roadsList.map((item: IRoadShareItem, i: number) => i === index ? {
                                            ...item,
                                            [key]: val
                                          } : item)
                                        })
                                      }}
                                    />
                                  </FormGroup>
                                ))
                              }
                            </>
                          ))
                        }
                      </>
                    ))
                  }
                  <FormGroup>
                    <label htmlFor={`newObject.ongoingProjects`}>
                      {t(`roadShareStats.ongoingProjects`, lang)}
                    </label>
                    <Field
                      name={`newObject.ongoingProjects`}
                      className={`${errors[`newObject.ongoingProjects`] ? 'error' : ''}`}
                      value={road['ongoingProjects'] || ''}
                      as="textarea"
                      onChange={(e: any) => {
                        const val = e.target.value;
                        setFieldValue(`newObject.ongoingProjects`, val);
                        setNewObject({
                          ...newObject,
                          roadsList: newObject.roadsList.map((item: IRoadShareItem, i: number) => i === index ? {
                            ...item,
                            ongoingProjects: val
                          } : item)
                        })
                      }}
                    />
                  </FormGroup>

                </FormBlock>
              ))}

              <FormBlockButton onClick={addRoad}>
                <AddField /> {t('roadShareStats.add-road', lang)}
              </FormBlockButton>
            </FormBlock>
          }

          {
            isAdding
              ? <>
                <FormBlockButton onClick={handleAddObject}>{t('save', lang)}</FormBlockButton>
                <FormBlockButton style={{ marginBottom: 10 }} onClick={() => setIsAdding(false)}>{t('cancel', lang)}</FormBlockButton>
              </>
              : <FormBlockButton style={{ marginBottom: 10 }} onClick={() => setIsAdding(true)}>
                <AddField /> {t('form.add_field', lang)}
              </FormBlockButton>
          }

        </FormBlock>
      }
    </div>

  }

  useEffect(() => {
    loadForm();
  }, [loadForm, snp]);

  useEffect(() => {
    kato && updateKatoAccessList(katoAccessList, kato, navigate, setTree, setRegion, setSnp);
  }, [katoAccessList, kato]);

  useEffect(() => {
    if (kato) {
      setIsKatoRegion(isRegion(+kato))
    }
  }, [kato])

  useEffect(() => {
    const item = localStorage.getItem('user');
    if (item) {
      const snpInfo = JSON.parse(item);
      if (snpInfo && snpInfo.kato_access) {
        setKatoAccessList(snpInfo.kato_access)
      }
    }
  }, [])

  return (
    <div style={{ position: 'relative' }}>
      {
        !isLoading && <>
          <Wrapper ref={wrapperRef}>
            <Formik
              initialValues={{}}
              onSubmit={(values) => {
                handleSubmitForm(values);;
              }}
              innerRef={formikRef}
            >
              {({ setFieldValue }) => (
                <Form>
                  {renderSelects(getLang())}
                  {renderSelects(getLang() !== 'Kz' ? 'Kz' : 'Ru')}
                  {renderFields(getLang(), setFieldValue)}
                  {renderFields(getLang() !== 'Kz' ? 'Kz' : 'Ru', setFieldValue)}
                  <Footer>
                    <div className="buttons">
                      <FooterButton variant="save"><Save /> {t('save', language)}</FooterButton>
                    </div>
                    <div className="buttons">
                      <FooterButton variant="go-up" type='button' onClick={() => handleGoUp(wrapperRef)}><GoUp /> {t('go-up', language)}</FooterButton>
                    </div>
                  </Footer>
                </Form>
              )}
            </Formik>
          </Wrapper>
        </>
      }
      <ToastContainer />
    </div>
  )
}

export default RoadsConditionPage