
export const populationDynamicOptions: any = {
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    x: {
      grid: {
        display: false,
        drawTicks: false,
      },
      position: 'top',
      ticks: {
        color: '#202224',
        align: 'center',
        baseLine: 'middle',
        font: {
          size: 14,
          weight: 400,
        },
      },
      offset: true,
    },
    y: {
      grid: {
        color: '#c6cacc',
        beginAtZero: false,
        drawTicks: false,
      },
      ticks: {
        display: false,
        maxTicksLimit: 4,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: true,
      color: '#202224',
      font: {
        size: 14,
        weight: 400,
      },
      textAlign: 'center',
      clamp: true,
      anchor: 'start',
      align: (context: any) => {
        const index = context.dataIndex;
        const datasets = context.chart.data.datasets[0].data;
        const average = datasets.reduce((acc: number, item: number) => acc + item, 0) / datasets.length;
        return datasets[index] <= average ? 'end' : 'start';
      },
    }
  },
  layout: {
    backgroundColor: '#F8F7EF',
  },
}

export const populationByAgeOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
        drawTicks: false,
      },
    },
    y: {
      stacked: true,
      ticks: {
        beginAtZero: true,
        display: false,
      },
      grid: {
        drawTicks: false,
        color: 'rgba(0, 0, 0, 0.05)',
      },
      max: (context: any) => {
        const datasets: any = context.chart.data.datasets;
        const maxSum: any = Math.max(...context.chart.data.labels.map((_: any, index: any) =>
          datasets.reduce((sum: any, dataset: any) => sum + (+dataset.data[index] || 0), 0)
        ));

        return maxSum * 1.275;
      },
    },
  },
  plugins: {
    datalabels: {
      align: 'start',
      anchor: 'end',
      font: {
        size: 12,
        weight: 'bold',
      },
      color: '#fff'
    },
    tooltip: {
      callbacks: {
        label: (context: any) => {
          const label = context.dataset.label || '';
          return `${label}: ${context.raw}`;
        },
      },
    },
    legend: {
      display: false,
    },
  },
}

export const populationBySocialStatusOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      }
    },
    y: {
      stacked: true,
      beginAtZero: true,
      ticks: {
        display: false,
      }
    },
  },
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        filter: (legendItem: any) => ['I группа', 'II группа', 'III группа'].includes(legendItem.text),
        usePointStyle: true,
        pointStyle: 'circle',
        boxHeight: 5
      },
    },
    datalabels: {
      display: (context: any) => {
        return context.dataset.data[context.dataIndex] !== null && context.dataset.data[context.dataIndex] !== 0;
      },
      align: (context: any) => {
        return context.dataset.label === 'I группа' || context.dataset.label === 'II группа' || context.dataset.label === 'III группа'
          ? 'center'
          : 'start';
      },
      anchor: (context: any) => {
        return context.dataset.label === 'I группа' || context.dataset.label === 'II группа' || context.dataset.label === 'III группа'
          ? 'center'
          : 'end';
      },
      font: {
        size: 11,
        weight: 'bold',
      },
      color: '#fff'
    },
  },
}

export const employmentOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      }
    },
    y: {
      stacked: true,
      beginAtZero: true,
      ticks: {
        display: false,
      }
    },
  },
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle',
        boxHeight: 5
      },
    },
    datalabels: {
      display: (context: any) => {
        return context.dataset.data[context.dataIndex] !== null && context.dataset.data[context.dataIndex] !== 0;
      },
      align: 'center',
      anchor: 'center',
      font: {
        size: 11,
        weight: 'bold',
      },
      color: '#fff'
    },
  }
}

export const mcbSubjectsOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  indexAxis: 'y',
  scales: {
    x: {
      beginAtZero: true,
      stacked: true,
      ticks: {
        display: false,
        maxTicksLimit: 6,
        align: 'start'
      },
      grid: {
        drawTicks: false,
      }
    },
    y: {
      stacked: true,
      ticks: {
        color: '#202224',
        crossAlign: 'far',
        font: {
          size: 12,
          weight: 400,
        },
      },
    },
  },
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        filter: (legendItem: any) => !['Всего'].includes(legendItem.text),
        usePointStyle: true,
        pointStyle: 'circle',
        boxHeight: 5,
      },
    },
    tooltip: {
      yAlign: 'bottom',
    },
    datalabels: {
      display: (context: any) => {
        return context.datasetIndex === 0 || context.datasetIndex === 4;
      },
      formatter: (value: any, context: any) => {
        if (context.datasetIndex === 0) {
          return context.chart.data.datasets[0].data[0];
        } else if (context.datasetIndex === 4) {
          const totalValue = context.chart.data.datasets[1].data[1] +
            context.chart.data.datasets[2].data[1] +
            context.chart.data.datasets[3].data[1] +
            context.chart.data.datasets[4].data[1]
          return totalValue;
        }
        return '';
      },
      anchor: 'end',
      align: 'start',
      font: {
        size: 11,
        weight: 'bold',
      },
      color: '#fff',
      clip: true,
    },
  },
}

export const registeredSubjectsOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
        drawTicks: false,
      },
    },
    y: {
      stacked: true,
      ticks: {
        beginAtZero: true,
        display: false,
      },
      grid: {
        drawTicks: false,
        color: 'rgba(0, 0, 0, 0.05)',
      },
      max: (context: any) => {
        const datasets: any = context.chart.data.datasets;
        const maxSum: any = Math.max(...context.chart.data.labels.map((_: any, index: any) =>
          datasets.reduce((sum: any, dataset: any) => sum + (dataset.data[index] || 0), 0)
        ));

        return maxSum * 1.275;
      },
    },
  },
  plugins: {
    datalabels: {
      align: 'end',
      anchor: 'end',
      font: {
        size: 14,
        weight: 'bold',
      },
      color: '#000'
    },
    tooltip: {
      callbacks: {
        label: (context: any) => {
          const label = context.dataset.label || '';
          return `${label}: ${context.raw}`;
        },
      },
    },
    legend: {
      display: false,
    },
  },
}