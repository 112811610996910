import React, { ChangeEvent, useState } from 'react'
import Modal from '../modal-component';

import { AddPhotoButton, FooterButton } from '../admin-page/admin-page.styles'
import { FormBlockButton } from './infrastructure.styles'

import { AddField, Pen } from '../../assets/icons';
import { deleteImage, handleEditImage, uploadGeneralInfoImage, uploadInfrastructureImage, uploadStreetImage } from '../../requests/supervisor.request';
import { FileDateType, IProject } from '../../interfaces/snp.interface';
import { ToastContainer, toast } from 'react-toastify';
import { t } from '../../utils/helpers.utils'
import { getImageUrl } from '../admin-page/general-info.component';

interface IProps {
  formData: IProject;
  loadForm: (objectId: number) => void;
  type: string;
  images: any;
  lang: string;
}

const ImageGrid: React.FC<IProps> = ({ formData, loadForm, type, images, lang }) => {

  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedImageName, setSelectedImageName] = useState('');
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [uploadType, setUploadType] = useState<FileDateType | ''>('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [imageIdToEdit, setImageIdToEdit] = useState<number | null>(null);

  const openPhotoModal = () => {
    setIsPhotoModalOpen(true);
  };

  const closePhotoModal = () => {
    setIsPhotoModalOpen(false);
  };

  const handleImageSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const fileName = file.name || '';
      setSelectedImageName(fileName);
      setSelectedImage(file);
    }
  };

  const handleImageUpload = async () => {
    if (!selectedImage) {
      toast.error(t('choose-file', lang));
      return;
    }

    if (!uploadType) {
      toast.error(t('choose-upload-type', lang));
      return;
    }

    const date = selectedDate && new Date(selectedDate);

    if ((date && date.getFullYear() > 2030) || (date && date.getFullYear().toString().length !== 4)) {
      toast.error(t('incorrect-date'));
      return;
    }

    if (!selectedDate) {
      toast.error(t('choose-date'));
      return;
    }

    const uploadRequest = ['village_road_street', 'lighting_street'].includes(type.toLowerCase()) ? uploadStreetImage : uploadInfrastructureImage;

    if (type === 'general') {
      date && uploadGeneralInfoImage(formData.kato, selectedImage)
        .then(() => {
          loadForm(formData.kato);
        })
    } else {
      date && uploadRequest(formData.kato, formData.id, type, date, uploadType as FileDateType, selectedImage)
        .then(_ => {
          loadForm(formData.id);
        })
    }

    closePhotoModal();
  };

  const handleDeleteImage = () => {
    imageIdToEdit && deleteImage(imageIdToEdit).then(_ => {
      loadForm(formData.id)
      setImageIdToEdit(null);
      setSelectedDate(null);
      setUploadType('');
      setIsDeleteModalOpen(false);
    })
  }

  const handleOpenEdit = (imageId: number) => {
    setIsEditModalOpen(true);
    setImageIdToEdit(imageId);
  }

  const handleCloseEdit = () => {
    setIsEditModalOpen(false);
  }

  const handleImageEdit = () => {
    const date = selectedDate && new Date(selectedDate);

    if ((date && date.getFullYear() > 2030) || (date && date.getFullYear().toString().length !== 4)) {
      toast.error('Некорректная дата');
      return;
    }

    if (!selectedDate) {
      toast.error('Выберите дату');
      return;
    }

    if (imageIdToEdit && selectedDate && uploadType) {
      handleEditImage({
        fileId: imageIdToEdit,
        date: new Date(selectedDate).toDateString(),
        fileType: uploadType
      })
        .then(_ => {
          loadForm(formData.id)
          setImageIdToEdit(null);
          setSelectedDate(null);
          setUploadType('');
          setIsEditModalOpen(false);
        })
    }
  }

  return (
    <>
      <div className='mb-4 image-grid-general images-grid'>
        <AddPhotoButton>
          <FormBlockButton onClick={openPhotoModal}>
            <AddField /> {t('form.add_photo', lang)}
          </FormBlockButton>
        </AddPhotoButton>
        {
          images && images.map((id: any) => (
            <div className='image-item' onClick={() => handleOpenEdit(id)} key={id} >
              <img src={getImageUrl(id)} alt="" />
              <div className="overlay">
                <Pen stroke='#3399ff' />
                {t('edit', lang)}
              </div>
            </div>
          ))
        }
      </div>

      <Modal isFileUpload isOpen={isPhotoModalOpen} onClose={closePhotoModal}>
        <label className="input-file">
          <span className="input-file-text">{selectedImageName}</span>
          <input type="file"
            accept="image/*"
            id="imageInput"
            onChange={handleImageSelect} />
          <span className="input-file-btn">{t('choose-file', lang)}</span>
        </label>
        <select name="uploadType" id="uploadType" value={uploadType} onChange={(e) => setUploadType(e.target.value as FileDateType)}>
          <option value="" selected hidden></option>
          {
            ['IMAGE_AFTER', 'IMAGE_CURRENT'].map((type) => (
              <option key={type} value={type}>{t(type, lang)}</option>
            ))
          }
        </select>
        <input type="date" onChange={(e) => setSelectedDate(e.target.value)} max={'2030-01-01'} />

        <button className="upload-button" onClick={handleImageUpload} type='button'>
          {t('form.photo.upload', lang)}
        </button>
      </Modal>

      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <div className="deleteModalContainer">
          <div>{t('delete-photo', lang)}</div>
          <div className="buttons-container">
            <FooterButton variant='delete' onClick={handleDeleteImage}> {t('delete', lang)}</FooterButton>
            <FooterButton variant='cancel' onClick={() => setIsDeleteModalOpen(false)}> {t('keep', lang)}</FooterButton>
          </div>
        </div>
      </Modal>

      <Modal isOpen={isEditModalOpen} onClose={handleCloseEdit}>
      
        <div className="buttons-container">

          <FooterButton onClick={handleCloseEdit} type='button' variant='cancel'>
            {t('cancel', lang)}
          </FooterButton>

          <FooterButton
            onClick={() => {
              setIsEditModalOpen(false)
              setIsDeleteModalOpen(true)
            }}
            type='button'
            variant='delete'
          >
            {t('delete', lang)}
          </FooterButton>
        </div>

      </Modal>

      <ToastContainer />

    </>
  )
}

export default ImageGrid