import React, { FC, useEffect, useState } from 'react'
import { OBLAST_KATO } from '../../../utils/helpers.utils'
import styled from 'styled-components';
import { ScreeningContainer } from '../tabs/index.styles';
import { useTranslation } from 'react-i18next';
import AllPsdTab from '../psd-tabs/all-psd-tab.component';
import TopTenPsd from '../psd-tabs/top-ten-psd.component';

const aktobeLink = 'https://app.powerbi.com/view?r=eyJrIjoiM2UwMjQzYzAtNjQ4MS00MmY5LTljYzEtNDlmYmM0ZjA1N2NjIiwidCI6ImU2MjQzNTFhLWViZGItNDUwMi1hNGEzLTRiZWI3OTYxODBlZiIsImMiOjl9';
const irgizLink = 'https://app.powerbi.com/view?r=eyJrIjoiYzdlNjJmZmUtMTBiNi00NTFjLWFjZGQtYmRiZjM5OTE0NmVlIiwidCI6ImU2MjQzNTFhLWViZGItNDUwMi1hNGEzLTRiZWI3OTYxODBlZiIsImMiOjl9';
const otherLink = 'https://app.powerbi.com/view?r=eyJrIjoiYzA1ZjI0ZTMtOGRhZS00NjVlLTliOTgtMWVmOTk5ODE1MzU4IiwidCI6ImU2MjQzNTFhLWViZGItNDUwMi1hNGEzLTRiZWI3OTYxODBlZiIsImMiOjl9';

const newIrgizLink = 'https://pbi.csi.kz/Report1/powerbi/Aktobe/Aktobe_%D0%B4%D0%BE%D1%85%D0%BE%D0%B4%D1%8B_%D1%80%D0%B0%D0%B9%D0%BE%D0%BD%D0%B0?rs:embed=true'

const PSDContainer = styled(ScreeningContainer)`
  .psd-body {
    background: #fff;
    height: 100%;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.625rem;
    > div {
      max-width: 99%; 
    }
  }
`;

const tabs = ['all', 'top', 'tables']

const PSDFullScreen: FC<{ kato: string }> = ({ kato }) => {
  const { t } = useTranslation();

  const [link, setLink] = useState<string>(aktobeLink);
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0]);
  const getLink = () => {
    if (+kato === OBLAST_KATO) {
      setLink(aktobeLink)
    } else if (+kato === 156800000) {
      setLink(irgizLink)
    } else {
      setLink(otherLink)
    }
  }

  useEffect(() => {
    getLink()
  }, [kato])

  return (
    <PSDContainer>
      <div className="tabs">
        {tabs.map((tab: string) => (
          <div
            key={tab}
            className={selectedTab === tab ? 'tab active' : 'tab'}
            onClick={() => setSelectedTab(tab)}
          >
            {t('psd-page.' + tab)}
          </div>
        ))}
      </div>

      <div className="psd-body">
        {selectedTab === tabs[0] && <AllPsdTab />}
        {selectedTab === tabs[1] && <TopTenPsd />}
      </div>

    </PSDContainer>
  )
}

export default PSDFullScreen