import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Text } from '../../text.component';

import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BasicStatsItem } from '../index.styles';
import { topFiveByActivityTypeOptions, topFiveByInvestmentSourceOptions } from './chart-options';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ChartDataLabels);

const Filter = styled.div`
  display: flex;
  gap: 2.5rem;
  height: 50px;
  align-items: center;
  padding: 0 1.25rem;

  .years-checkboxes {
    display: flex;  
    gap: 1.25rem;
    align-items: center;  
  }

  .container {
    display: flex;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    align-items: center;
    gap: 0.5rem;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    display: inline-block;
    height: 18px;
    width: 18px;
    border-radius: 3px;
    border: 1px solid rgba(219, 220, 224, 1);
    position: relative;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #118f84;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 6px;
    top: 2px;
    transform: translate(-50%, -50%);
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .district-select {
    display: flex;
    gap: 0.625rem;
    align-items: center;

    select {
      height: 32px;
      width: 375px;
      border: 1px solid #DBDCE0;
      border-radius: 5px;
      font-size: 0.75rem;

      &:focus {
        outline: none;
      }

      &:placeholder {
        color: #818689;
      }
    }
  }

  .date {
    display: flex;
    gap: 0.3125rem;
    align-items: center;
    justify-self: flex-end;
    margin-left: auto;
  }
`;

const years = ['all-years', '2022', '2023', '2024'];


const topFiveByActivityTypeData = {
  labels: ['Строительство', 'Реконструкция', 'Ремонт', 'Другое', 'Установка'],
  datasets: [
    {
      label: 'Количество ПСД',
      data: [220, 58, 46, 27, 18],
      backgroundColor: "#118F84",
      barThickness: 9,
      borderRadius: 2,
    }
  ]
}

const topFiveByObjectTypeData = {
  labels: ['Дорога', 'МСБ', 'Другое', 'Здание', 'Школа'],
  datasets: [
    {
      label: 'Количество ПСД',
      data: [108, 58, 46, 27, 18],
      backgroundColor: "#118F84",
      barThickness: 9,
      borderRadius: 2,
    }
  ]
}

const topFiveByInvestmentSourceData = {
  labels: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
  datasets: [
    {
      label: 'Частные',
      data: [30, 40, 45, 35, 50, 55, 40, 30, 45, 40, 50, 55],
      backgroundColor: '#5BC7D9',
      barThickness: 18,
      borderRadius: 5,
    },
    {
      label: 'Квазигосударственные',
      data: [20, 30, 35, 25, 40, 45, 35, 20, 35, 30, 40, 45],
      backgroundColor: '#92D050',
      barThickness: 18,
      borderRadius: 5,
    },
    {
      label: 'Государственные',
      data: [10, 20, 25, 15, 30, 35, 25, 10, 25, 20, 30, 35],
      backgroundColor: '#118F84',
      barThickness: 18,
      borderRadius: 5,
    },
  ]
};


const AllPsdTab = () => {
  const { t } = useTranslation();
  const [selectedYears, setSelectedYears] = useState<string[]>([]);

  const handleCheckboxChange = (year: string) => {
    if (year === 'all-years') {
      if (selectedYears.length === years.length - 1) {
        setSelectedYears([]);
      } else {
        setSelectedYears(years.filter(y => y !== 'all-years'));
      }
    } else {
      let updatedSelectedYears = [...selectedYears];

      if (selectedYears.includes(year)) {
        updatedSelectedYears = updatedSelectedYears.filter(y => y !== year);
      } else {
        updatedSelectedYears.push(year);
      }

      const yearGroup = ['2022', '2023', '2024'];
      const allGroupSelected = yearGroup.every(y => updatedSelectedYears.includes(y));

      if (allGroupSelected) {
        updatedSelectedYears = [...new Set([...updatedSelectedYears, ...yearGroup])];
      }

      setSelectedYears(updatedSelectedYears);
    }
  };

  return (
    <>
      <Filter>
        <div className='years-checkboxes'>
          {years.map((year) => (
            <label className='container'>
              <input
                type="checkbox"
                checked={year === 'all-years' ? selectedYears.length === years.length - 1 : selectedYears.includes(year)}
                onChange={() => handleCheckboxChange(year)}
                id={year}
                name={year}
              />
              <span className="checkmark" />
              <Text
                fontSize='0.75rem'
                fontWeight={selectedYears.includes(year) || selectedYears.length === years.length - 1 ? 600 : 400}
                color={selectedYears.includes(year) || selectedYears.length === years.length - 1 ? '#118f84' : 'rgba(129, 134, 137, 1)'}
              >
                {t('psd-page.' + year)}
              </Text>
            </label>
          ))}
        </div>

        <div className="district-select">
          <Text fontSize='0.75rem' fontWeight={400} color='#202224'>Район:</Text>
          <select>
            <option value="all" selected disabled>Все районы</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
        </div>

        <div className="date">
          <Text fontSize='0.75rem' fontWeight={400} color='#818689'>отчет на</Text>
          <Text fontSize='0.75rem' fontWeight={400} color='#202224'>14 августа 2024</Text>
        </div>
      </Filter>

      <div className="grid">
        <BasicStatsItem>
          <div className="title">
            <div className="left">
              <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>ТОП - 5 ПСД</Text>
              <Text color='#818689' fontWeight={400} fontSize='0.75rem'>по виду деятельности</Text>
            </div>
          </div>
          <div className="body">
            <Bar data={topFiveByActivityTypeData} options={topFiveByActivityTypeOptions} plugins={[ChartDataLabels]} />
          </div>
        </BasicStatsItem>
        <BasicStatsItem>
          <div className="title">
            <div className="left">
              <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>ТОП - 5 ПСД</Text>
              <Text color='#818689' fontWeight={400} fontSize='0.75rem'>по виду объектов</Text>
            </div>
          </div>
          <div className="body">
            <Bar data={topFiveByObjectTypeData} options={topFiveByActivityTypeOptions} plugins={[ChartDataLabels]} />
          </div>
        </BasicStatsItem>
        <BasicStatsItem>
          <div className="title">
            <div className="left">
              <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>ПСД по источнику инвестиций</Text>
            </div>
          </div>
          <div className="body">
            <Bar data={topFiveByInvestmentSourceData} options={topFiveByInvestmentSourceOptions} plugins={[ChartDataLabels]} />
          </div>
        </BasicStatsItem>
      </div>
    </>
  );
};

export default AllPsdTab;
