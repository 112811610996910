import React, { FC, useEffect, useState } from 'react'
import { BasicStatsItem } from '../index.styles'
import { Text } from '../../text.component'
import { Bar, Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { EmploymentCategories, IHumanResources, SocialCategories } from '../../../interfaces/snp.interface';
import { employmentOptions, populationByAgeOptions, populationBySocialStatusOptions, populationDynamicOptions } from './chart-options';

interface IProps {
  data: IHumanResources
}

const HumanResourcesTab: FC<IProps> = ({ data }) => {
  const [populationDynamic, setPopulationDynamic] = useState<any>(null);
  const [populationByAge, setPopulationByAge] = useState<any>(null);
  const [populationBySocialStatus, setPopulationBySocialStatus] = useState<any>(null);
  const [employment, setEmployment] = useState<any>(null);

  const buildGraphs = () => {
    if (data) {
      setPopulationDynamic(
        {
          labels: data.populationDynamic?.map((item: any) => item.year),
          datasets: [
            {
              label: 'Население',
              data: data.populationDynamic?.map((item: any) => item.population),
              borderColor: '#6968FD',
              backgroundColor: '#6968FD',
              pointRadius: 4,
              pointBackgroundColor: '#6968FD',
            }
          ]
        }
      );

      setPopulationByAge({
        labels: data.populationByAge?.map((item: any) => item.age),
        datasets: [
          {
            label: 'Население',
            data: data.populationByAge?.map((item: any) => item.population),
            backgroundColor: '#009187',
            borderRadius: 5,
            barThickness: 76,
          }
        ]
      });

      setPopulationBySocialStatus({
        labels: [['Получатели', 'АСП'], 'Пенсионеры', 'Многодетные', ['Люди с', 'огр.', 'возможностями']],
        datasets: [
          {
            label: 'Получатели АСП',
            data: [data.populationBySocialStatus?.[SocialCategories.TSArecipients], null, null, null],
            backgroundColor: '#009187',
            borderRadius: 5,
            barThickness: 76,
          },
          {
            label: 'Пенсионеры',
            data: [null, data.populationBySocialStatus?.[SocialCategories.pensioners], null, null],
            backgroundColor: '#009187',
            borderRadius: 5,
            barThickness: 76,
          },
          {
            label: 'Многодетные',
            data: [null, null, data.populationBySocialStatus?.[SocialCategories.largeFamilies], null],
            backgroundColor: '#009187',
            borderRadius: 5,
            barThickness: 76,
          },
          {
            label: 'I группа',
            data: [null, null, null, data.populationBySocialStatus?.[SocialCategories.firstGroupDisabled]],
            backgroundColor: '#5BC7D9',
            borderRadius: 5,
            barThickness: 76,
          },
          {
            label: 'II группа',
            data: [null, null, null, data.populationBySocialStatus?.[SocialCategories.secondGroupDisabled]],
            backgroundColor: '#92D050',
            borderRadius: 5,
            barThickness: 76,
          },
          {
            label: 'III группа',
            data: [null, null, null, data.populationBySocialStatus?.[SocialCategories.thirdGroupDisabled]],
            backgroundColor: '#2F6DBD',
            borderRadius: 5,
            barThickness: 76,
          },
        ]
      });

      setEmployment({
        labels: ['Наемные', 'Самозанятые', 'Безработные', ['Трудоспособные', 'пенсионеры']],
        datasets: [
          {
            label: 'Бюджет',
            data: [data.employment?.[EmploymentCategories.hired_budget], data.employment?.[EmploymentCategories.selfEmployed], data.employment?.[EmploymentCategories.unemployed], data.employment?.[EmploymentCategories.workingElderly]],
            backgroundColor: '#009187',
            borderRadius: 5,
            barThickness: 76,
          },
          {
            label: 'Частные',
            data: [data.employment?.[EmploymentCategories.hired_private], null, null, null],
            backgroundColor: '#92D050',
            borderRadius: 5,
            barThickness: 76,
          },
        ]
      })
    }
  }

  useEffect(() => {
    buildGraphs()
  }, [data])

  return (
    <div className='grid c-2 screening human-resources'>
      {
        data.populationDynamic && populationDynamic && <BasicStatsItem>
          <div className="title">
            <div className="left">
              <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>Динамика численности населения</Text>
            </div>
          </div>
          <div className="body">
            <Line data={populationDynamic} options={populationDynamicOptions} plugins={[ChartDataLabels]} />
          </div>
        </BasicStatsItem>
      }

      {data.populationByAge && populationByAge && <BasicStatsItem>
        <div className="title">
          <div className="left">
            <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>Численность населения по возрастным категориям</Text>
          </div>
        </div>
        <div className="body">
          <Bar data={populationByAge} options={populationByAgeOptions} plugins={[ChartDataLabels]} />
        </div>
      </BasicStatsItem>}

      {data.populationBySocialStatus && populationBySocialStatus && <BasicStatsItem>
        <div className="title">
          <div className="left">
            <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>Социальная категория граждан</Text>
          </div>
        </div>
        <div className="body">
          <Bar data={populationBySocialStatus} options={populationBySocialStatusOptions} plugins={[ChartDataLabels]} />
        </div>
      </BasicStatsItem>}

      {data.employment && employment && <BasicStatsItem>
        <div className="title">
          <div className="left">
            <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>Занятость</Text>
          </div>
        </div>
        <div className="body">
          <Bar data={employment} options={employmentOptions} plugins={[ChartDataLabels]} />
        </div>
      </BasicStatsItem>}
    </div>
  )
}

export default HumanResourcesTab