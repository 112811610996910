import React, {  useEffect, useState } from 'react'
import { BasicStatsItem } from '../index.styles'
import { Text } from '../../text.component'
import { Line, Bar } from 'react-chartjs-2'
import { Cards } from '../tabs/index.styles';
import { useTranslation } from 'react-i18next';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { properties } from '../basic-stats.component';
import { TrendDown, TrendUp } from '../../../assets/icons';
import { getGeneralInfo } from '../../../requests/snp.request';
import { useParams } from 'react-router-dom';

const generateOptions = (fontCoef: number): any => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: 'right',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle',
        fill: true,
        boxHeight: 3 * fontCoef,
        font: {
          size: 10 * fontCoef,
        },
      },
    },
    datalabels: {
      display: 'auto',
      formatter: function (value: any) {
        return value;
      },
      font: {
        size: 10 * fontCoef,
      },
      textAlign: 'center',
      clamp: true,
      anchor: 'center',
      align: 'bottom',
    }
  },
  scales: {
    x: {
      grid: {
        display: false,
        beginAtZero: false,
      },
      position: 'top',
      ticks: {
        drawTicks: false,
        font: {
          size: 10 * fontCoef,
          weight: 'bold',
        },
        padding: 6,
      },
    },
    y: {
      ticks: {
        display: false,
        padding: 10,
      },
      grid: {
        beginAtZero: false,
        drawTicks: false,
      },
    }
  }
})

const data = {
  labels: ['2020', '2021', '2022'],
  datasets: [
    {
      label: 'МРС',
      data: [102216, 103023, 88591],
      borderColor: '#009187',
      backgroundColor: '#009187',
      tension: 0.1,
    },
    {
      label: 'КРС',
      data: [37019, 41816, 38413],
      borderColor: '#7dd259',
      backgroundColor: '#7dd259',
      tension: 0.1
    },
    {
      label: 'Лошади',
      data: [10091, 12655, 17444],
      borderColor: '#00cadc',
      backgroundColor: '#00cadc',
      tension: 0.1
    },
    {
      label: 'Птицы',
      data: [10738, 13694, 7368],
      borderColor: '#006ec3',
      backgroundColor: '#006ec3',
      tension: 0.1
    },
    {
      label: 'Верблюды',
      data: [1141, 1220, 1041],
      borderColor: '#595959',
      backgroundColor: '#595959',
      tension: 0.1
    },
  ]
}

const animalHusbandry: any = {
  data: {
    labels: ['КРС', 'МРС', 'Лошади', 'Верблюды'],
    datasets: [
      {
        label: 'ЛПХ',
        data: [9787, 27373, 4975, 652],
        backgroundColor: '#009187',
        borderWidth: 0,
        borderRadius: 10,
      },
      {
        label: 'ТОО/КХ',
        data: [24431, 61218, 12469, 389],
        backgroundColor: '#7dd259',
        borderWidth: 0,
        borderRadius: 10,
      },
      {
        label: '',
        data: [0, 0, 0, 0],
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        borderWidth: 0,
        borderRadius: 10,
      },
    ]
  },
  generateOptions: (fontCoef: number): any => ({
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
          drawTicks: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true,
          display: false,
        },
        grid: {
          drawTicks: false,
          color: 'rgba(0, 0, 0, 0.05)',
        },
        max: (context: any) => {
          const datasets: any = context.chart.data.datasets;
          const maxSum: any = Math.max(...context.chart.data.labels.map((_: any, index: any) =>
            datasets.reduce((sum: any, dataset: any) => sum + (dataset.data[index] || 0), 0)
          ));

          return maxSum * 1.275;
        },
      },
    },
    plugins: {
      datalabels: {
        display: (context: any) => {
          const datasetIndex = context.datasetIndex;
          return datasetIndex === 2 ? true : 'auto';
        },
        align: (context: any) => {
          const datasetIndex = context.datasetIndex;
          return datasetIndex === 2 ? 'end' : 'center';
        },
        anchor: 'center',
        formatter: (value: any, context: any) => {
          const datasetIndex = context.datasetIndex;
          const dataIndex = context.dataIndex;
          if (datasetIndex === 2) {
            const sum = context.chart.data.datasets.reduce((acc: any, dataset: any) => acc + dataset.data[dataIndex], 0);
            return Number.isInteger(sum) ? sum : sum.toFixed(1);
          }
          return value;
        },
        font: {
          size: 12 * fontCoef,
          weight: 'bold',
        },
        color: (context: any) => {
          const datasetIndex = context.datasetIndex;
          return datasetIndex === 2 ? '#009187' : '#fff';
        },

      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const label = context.dataset.label || '';
            return `${label}: ${context.raw}`;
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          fill: true,
          boxHeight: 3 * fontCoef,
          font: {
            size: 12 * fontCoef,
          },
        },
      },
    },
  }),
}

const meatProduction: any = {
  data: {
    labels: ['КРС', 'МРС', 'Лошади', 'Верблюды'],
    datasets: [
      {
        label: 'ЛПХ',
        data: [303.20, 287.30, 78.70, 76.20],
        backgroundColor: '#009187',
        borderWidth: 0,
        borderRadius: 10,
      },
      {
        label: 'ТОО/КХ',
        data: [757.2, 642.6, 198.1, 9.2],
        backgroundColor: '#7dd259',
        borderWidth: 0,
        borderRadius: 10,
      },
      {
        label: '',
        data: [0, 0, 0, 0],
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        borderWidth: 0,
        borderRadius: 10,
      },
    ]
  },
}

const yearlyMilkProduction: any = {
  data: {
    labels: [1],
    datasets: [
      {
        label: ["Животноводство", 'молочного', 'направления',],
        data: [4195],
        backgroundColor: '#7dd259',
        borderWidth: 1,
        borderRadius: 5,
        barThickness: 40,
        borderColor: 'transparent',
      },
      {
        label: ["Производство", "молока, тонн"],
        data: [1572.7],
        backgroundColor: '#009187',
        borderWidth: 1,
        borderRadius: 5,
        barThickness: 40,
        borderColor: 'transparent',
      }
    ]
  },
  generateOptions: (fontCoef: number): any => ({
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    scales: {
      x: {
        display: false,
        max: 4195
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          fill: true,
          boxHeight: 3 * fontCoef,
          font: {
            size: 10 * fontCoef,
          },
        },
        maxWidth: 110,
      },
      datalabels: {
        display: 'auto',
        font: {
          size: 10 * fontCoef,
        },
        clamp: true,
        anchor: 'end',
        align: 'start',
        color: "#FFF"
      }
    },
  })
}

const milkProductiom: any = {
  data: {
    labels: [['Сливочное', 'масло'], 'Творог', 'Сметана', 'Кефир', 'Молоко'],
    datasets: [
      {
        label: "Ежегодный выпуск, тонн",
        backgroundColor: '#7dd259',
        data: [16.33, 27.53, 39.3, 286.4, 235.9],
        borderRadius: 4,
      },
      {
        label: 'Средний расход за год, тыс. литров',
        backgroundColor: '#009187',
        data: [393.1, 165.4, 393.1, 385.2, 235.9],
        borderRadius: 4,
      }
    ]
  },
  generateOptions: (fontCoef: number): any => ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          fill: true,
          boxHeight: 3 * fontCoef,
          font: {
            size: 8.5 * fontCoef,
          },
        },
        maxWidth: 60,
      },
      datalabels: {
        display: true,
        padding: {
          left: 4,
          right: 4,
        },
        font: {
          size: 10 * fontCoef,
          weight: 'bold',
        },
        clamp: true,
        anchor: 'end',
        align: 'top',
        color: "#009187"
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawTicks: false,
        },
        ticks: {
          font: {
            size: 10 * fontCoef,
          }
        }
      },
      y: {
        display: false,
        max: (context: any) => {
          const datasets: any = context.chart.data.datasets;
          const maxSum = Math.max(...datasets.map((item: any, index: any) => Math.max(...item.data)))

          return maxSum + 70;
        },
      },
    },
  })
}

const cropArea: any = {
  data: {
    labels: ['КХ', 'ЛПХ'],
    datasets: [
      {
        label: 'Картофель',
        data: [7.00, 9.92],
        backgroundColor: '#7dd259',
        borderRadius: 4,
        borderWidth: 0,
      },
      {
        label: 'Овощи и бахчевые',
        data: [19.00, 25.70],
        backgroundColor: '#009187',
        borderRadius: 4,
        borderWidth: 0,
      }
    ]
  },
  generateOptions: (fontCoef: number, unit: string): any => ({
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    scales: {
      x: {
        stacked: true,
        display: false,
        drawTicks: false,
        max: (context: any) => {
          const datasets: any = context.chart.data.datasets;
          const maxSum: any = Math.max(...context.chart.data.labels.map((_: any, index: any) =>
            datasets.reduce((sum: any, dataset: any) => sum + (dataset.data[index] || 0), 0)
          ));

          return maxSum * 1.275;
        },
        grid: {
          drawTicks: false,
        }
      },
      y: {
        stacked: true,
        grid: {
          display: false,
          drawTicks: false,
        },
        ticks: {
          beginAtZero: true,
          font: {
            size: 10 * fontCoef,
          }
        }
      }
    },
    plugins: {
      datalabels: {
        display: (context: any) => {
          const datasetIndex = context.datasetIndex;
          return datasetIndex === 1
        },
        anchor: 'end',
        align: 'right',
        formatter: (value: any, context: any) => {
          const dataIndex = context.dataIndex;
          const sum = context.chart.data.datasets.reduce((acc: any, dataset: any) => acc + dataset.data[dataIndex], 0);
          return (Number.isInteger(sum) ? sum : sum.toFixed(1)) + ` ${unit}`;
        },
        color: '#009187',
        font: {
          size: fontCoef * 12,
          weight: 'bold',
        }
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          fill: true,
          boxHeight: 5 * fontCoef,
          font: {
            size: 10 * fontCoef,
          },
        },
        maxWidth: 60,
      },
    }
  }),
}

const grossCollection = {
  data: {
    labels: ['КХ', 'ЛПХ'],
    datasets: [
      {
        label: 'Картофель',
        data: [53.95, 75.72],
        backgroundColor: '#7dd259',
        borderRadius: 4,
        borderWidth: 0,
      },
      {
        label: 'Овощи и бахчевые',
        data: [181.40, 207.55],
        backgroundColor: '#009187',
        borderRadius: 4,
        borderWidth: 0,
      }
    ]
  },
}

const foodKeys = ['BEEF', 'CHICKEN', 'EGG', 'CUCUMBER', 'TOMATO']

const gridItems = [
  {
    title: 'КРС',
    value: '38 413',
    unit: 'голов',
    kh: '29%',
    lph: '36%'
  },
  {
    title: 'МРС',
    value: '88 591',
    unit: 'голов',
    kh: '70%',
    lph: '30%'
  },
  {
    title: 'Лошади',
    value: '17 444',
    unit: 'голов',
    kh: '29%',
    lph: '71%'
  },
  {
    title: 'Верблюды',
    value: '1 041',
    unit: 'голов',
    kh: '63%',
    lph: '36%'
  }
]

const FarmingTab = () => {
  const { t } = useTranslation();
  const fontCoef = 1;
  const { kato } = useParams();
  const [food, setFood] = useState<any>(null);

  useEffect(() => {
    if (kato) {
      getGeneralInfo(+kato).then((res: any) => {
        const grouped = res.basicStats?.filter((item: any) => item.type !== 'ADDITIONAL_STAT').reduce((acc: any, stat: any) => {
          if (!acc[stat.type]) {
            acc[stat.type] = [];
          }
          acc[stat.type].push(stat);
          return acc;
        }, {});

        const filteredAndSorted = grouped.FOOD.filter((item: any) => foodKeys.includes(item.subType)).sort((a: any, b: any) => +b.increaseVal - +a.increaseVal)
        setFood(filteredAndSorted)
      })
    }
  }, [kato])

  return (
    food && <div className='grid c-3 screening farming'>
      <BasicStatsItem>
        <div className="title">
          <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t(`population-dynamic.title`)}</Text>
        </div>
        <div className="body">
          <div className="line-chart-container" style={{ height: '100%', maxHeight: '18rem' }}>
            <Line data={data} options={generateOptions(fontCoef)} plugins={[ChartDataLabels]} />
          </div>
        </div>
      </BasicStatsItem>
      <BasicStatsItem>
        <div className="title">
          <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>Самообеспеченность продуктами питания</Text>
        </div>
        <div className="body">
          {food.map((stat: any, index: number) => (
            <div className={`body__item with-icon stretched FOOD`} key={properties['FOOD'][stat.subType].toString() + index}>
              <div className="icon-container">
                <div className="icon">{properties['FOOD'][stat.subType].icon}</div>
                <Text fontSize='0.75rem' color='var(--text-color)' fontWeight={400}>
                  {t(`new-fields.${stat.subType}`)}
                </ Text>
              </div>
              <div className="increase">
                <Text fontSize='0.75rem' color={stat.increaseVal >= 0 ? 'var(--success-green)' : 'var(--failure-red)'} fontWeight={700}>
                  {stat.increaseVal >= 0 ? <TrendUp /> : <TrendDown />}
                  {+stat.increaseVal > 0 ? `+${stat.increaseVal}` : ` ${stat.increaseVal}`}%
                </Text>

                <Text className='percent-value' fontSize={'1rem'} color='var(--primary-color)' fontWeight={700}>
                  {stat.currentYearVal}{properties['FOOD'].unit}
                </Text>
              </div>
            </div>
          ))}
        </div>
      </BasicStatsItem>

      <BasicStatsItem>
        <div className="title">
          <div className="left">
            <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>Валовый сбор продуктов</Text>
            <Text color='#818689' fontWeight={400} fontSize='0.75rem'>в 2022</Text>
          </div>
        </div>
        <div className="body" style={{ justifyContent: 'flex-start', padding: '0.3125rem' }}>
          <table className="screening-item__table">
            <thead>
              <tr>
                <th>
                  <Text fontSize='0.75rem'>Валовый сбор продуктов <br /> в 2022 г</Text>
                </th>
                <th>
                  <Text fontSize='0.625rem' fontWeight={400}>сбор <br /> тонна</Text>
                </th>
                <th>
                  <Text fontSize='0.625rem' fontWeight={400}>покрытие <br /> %</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Text fontSize='0.625rem' >Картофель</Text>
                </td>
                <td>
                  <Text fontSize='0.625rem' color="var(--primary-color)" fontWeight={700}>129.6</Text>
                </td>
                <td>
                  <div className='increase'>
                    <Text fontSize='0.625rem' color="var(--success-green)">0.8% <TrendUp width={8} height={10} /></Text>
                    <Text fontSize='0.625rem' color="var(--primary-color)" fontWeight={700}>9.3</Text>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <Text fontSize='0.625rem' className='tal'>Огурцы</Text>
                </td>
                <td>
                  <Text fontSize='0.625rem' color="var(--primary-color)" fontWeight={700}>89.8</Text>
                </td>
                <td>
                  <div className='increase'>
                    <Text fontSize='0.625rem' color="var(--success-green)">1.8% <TrendUp width={8} height={10} /></Text>
                    <Text fontSize='0.625rem' color="var(--primary-color)" fontWeight={700}>45.9</Text>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <Text fontSize='0.625rem' className='tal'>Помидоры</Text>
                </td>
                <td>
                  <Text fontSize='0.625rem' color="var(--primary-color)" fontWeight={700}>57.6</Text>
                </td>
                <td>
                  <div className='increase'>
                    <Text fontSize='0.625rem' color="var(--failure-red)">-13.8%  <TrendDown width={8} height={10} /></Text>
                    <Text fontSize='0.625rem' color="var(--primary-color)" fontWeight={700}>9.3</Text>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <Cards>
            <div className="grid screening-grid">
              {gridItems.map((item, index) => (
                <div className="grid__item card" key={item.title + index}>
                  <Text fontSize='0.5rem'>{item.title}</Text>
                  <div className='value'>
                    <Text fontSize='1rem' color='var(--primary-color)' fontWeight={700}>{item.value}</Text>
                    <Text fontSize='0.5rem'>{item.unit}</Text>
                  </div>
                  <div className='percents'>
                    <Text fontSize='0.5rem'>{item.kh} - ТОО, КХ</Text>
                    <Text fontSize='0.5rem'>{item.lph} - ЛПХ</Text>
                  </div>
                </div>
              ))}
            </div>
          </Cards>
        </div>
      </BasicStatsItem>

      <BasicStatsItem>
        <div className="title">
          <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>Животноводство мясного направления</Text>
        </div>
        <div className="body" style={{ padding: '0.3125rem' }}>
          <div className="line-chart-container" style={{ height: '100%', maxHeight: '18rem' }}>
            <Bar data={animalHusbandry.data} options={animalHusbandry.generateOptions(fontCoef)} plugins={[ChartDataLabels]} />
          </div>
        </div>
      </BasicStatsItem>

      <BasicStatsItem>
        <div className="title">
          <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>КРС молочного направления и производство молока, ежегодно</Text>
        </div>
        <div className="body" style={{ padding: '0.3125rem' }}>
          <div className="line-chart-container" style={{ height: '100%', maxHeight: '18rem' }}>
            <Bar data={yearlyMilkProduction.data} options={yearlyMilkProduction.generateOptions(fontCoef)} plugins={[ChartDataLabels]} />
          </div>
        </div>
      </BasicStatsItem>

      <BasicStatsItem>
        <div className="title">
          <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>Посевная площадь растениеводства</Text>
        </div>
        <div className="body" style={{ padding: '0.3125rem' }}>
          <div className="line-chart-container" style={{ height: '100%', maxHeight: '18rem' }}>
            <Bar data={cropArea.data} options={cropArea.generateOptions(fontCoef, 'га')} plugins={[ChartDataLabels]} />
          </div>
        </div>
      </BasicStatsItem>

      <BasicStatsItem>
        <div className="title">
          <div className="left">
            <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>Ежегодное производство мяса</Text>
            <Text color='#818689' fontWeight={400} fontSize='0.75rem'>тонн</Text>
          </div>
        </div>
        <div className="body" style={{ padding: '0.3125rem' }}>
          <div className="line-chart-container" style={{ height: '100%', maxHeight: '18rem' }}>
            <Bar data={meatProduction.data} options={animalHusbandry.generateOptions(fontCoef)} plugins={[ChartDataLabels]} />
          </div>
        </div>
      </BasicStatsItem>

      <BasicStatsItem>
        <div className="title">
          <div className="left">
            <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>Производство молочной продукции</Text>
            <Text color='#818689' fontWeight={400} fontSize='0.75rem'>тонн</Text>
          </div>
        </div>
        <div className="body" style={{ padding: '0.3125rem' }}>
          <div className="line-chart-container" style={{ height: '100%', maxHeight: '18rem' }}>
            <Bar data={milkProductiom.data} options={milkProductiom.generateOptions(fontCoef)} plugins={[ChartDataLabels]} />
          </div>
        </div>
      </BasicStatsItem>

      <BasicStatsItem>
        <div className="title">
          <div className="left">
            <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>Валовый сбор продуктов растениеводства</Text>
          </div>
        </div>
        <div className="body" style={{ padding: '0.3125rem' }}>
          <div className="line-chart-container" style={{ height: '100%', maxHeight: '18rem' }}>
            <Bar data={grossCollection.data} options={cropArea.generateOptions(fontCoef, 'т')} plugins={[ChartDataLabels]} />
          </div>
        </div>
      </BasicStatsItem>
    </div>
  )
}

export default FarmingTab