import styled from "styled-components";
import { FormBlock } from "../../admin-page/admin-page.styles";

export const SERContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

 .tabs {
  width: 100%;
  background-color: #f6f6f6;
  display: flex;
  padding: 0.625rem 1.25rem;
  align-items: center;
  height: 3.125rem;
  gap: 0.625rem;
  border-bottom: 1px solid #DBDCE0;

  .tab {
    padding: 0.625rem 1rem;
    background: #E2E2E2;
    border-radius: 0.3125rem;
    font-size: 0.875rem;
    color: var(--text-color);
    cursor: pointer;

    &.active {
      background: linear-gradient(90deg, #226AC3 0%, #04A958 100%);
      color: #fff;
    }
  }
 }

  .close {
    position: absolute;
    top: -1.25rem;
    right: 0.625rem;
    cursor: pointer;
  }

  .dfc {
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
  }

  .left {
      width: 9.3125rem;
      background-color: var(--primary-color);
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      min-width: 9.3125rem;

      > div {
        text-transform: uppercase; 
        text-align: center;
      }
  }

  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;

    .budget-data__item {
      display: flex;
      padding: 1.25rem 0 1.25rem 1.875rem;
      border-radius: 0.625rem;
      border: 1px solid var(--borderGray);
      background-color: var(--bg-white);
      text-transform: uppercase;
      text-align: center;
      gap: 0;
      width: 100%;

      .rest {
        display: flex;
        gap: 0.625rem;
        flex: 1;
        padding-right: 0.625rem;

        > div {
          font-size: 1rem;
        }
        > div:first-child {
          font-size: 1.25rem;
        }
      }

      &:nth-child(odd) {
        background-color: var(--ser-gray);
      }

      > div:first-child {
        display: flex; 
        max-width: 14rem;
        align-items: center;
        gap: 0.625rem;
        flex: 1;
        text-align: left;
        width: 14rem;

        svg {
          min-width: 1.25rem;
          min-height: 1.25rem;
          max-width: 1.25rem;
          max-height: 1.25rem;

          path {
            fill: var(--icon-fill);
          }
        }
      }

      &:not(&.header) {
        > div:first-child {
          > div { 
            line-height: 0.875rem;
          } 
        }

        .rest {
          align-items: center;
        }
      }

      &.header {
        background: none;
        border: none;
        padding-top: 0;
        padding-bottom: 0;
        .name {
          align-items: start;        
        }
      }
    }
  }

  .doubled {
    display: flex;
    justify-content: space-between;
    width: 100%; 
    max-width: 180px;
  }

  ${FormBlock} {
    margin: 0; 
    padding: 0.625rem 0.875rem;
    border-radius: 0.625rem;
    background-color: var(--bg-white);
    overflow: hidden;
  }

  .ser-row {
    display: flex;
    justify-content: center;
    gap: 1.5rem;

    &.sb {
      justify-content: space-between;
    }
  }

  @media (max-width: 1280px) {
    .budget-data__item,
    .budget-data__item.header {
      .name {
        max-width: 10rem !important;

        > div {
          font-size: 0.75rem; 
        }
      }

      .rest {
        > div {
          font-size: 0.75rem !important;
          min-width: 7rem !important;
          > div {
            font-size: 0.75rem !important;
          }
        }
      }
    }

    .budget-data__item {
       .rest {
        > div {
          font-size: 0.9375rem !important;
          > div {
            font-size: 0.9375rem !important;
          }
        }
      }
    }
  }

  @media (min-width: 1281px) and (max-width: 1366px) {
    .budget-data__item,
    .budget-data__item.header {
      .name {
        max-width: 10rem !important;

        > div {
          font-size: 0.75rem; 
        }
      }

      .rest {
        > div {
          font-size: 0.75rem !important;
          min-width: 8rem !important;
          > div {
            font-size: 0.75rem !important;
          }
        }
      }
    }

    .budget-data__item {
       .rest {
        > div {
          font-size: 0.9375rem !important;
          > div {
            font-size: 0.9375rem !important;
          }
        }
      }
    }
  }
  @media (min-width: 1367px) and (max-width: 1440px) {
    .budget-data__item,
    .budget-data__item.header {
      .name {
        max-width: 13 rem !important;

        > div {
          font-size: 0.75rem; 
        }
      }

      .rest {
        > div {
          font-size: 0.75rem !important;
          min-width: 8rem !important;
          > div {
            font-size: 0.75rem !important;
          }
        }
      }
    }

    .budget-data__item {
      .rest {
        > div {
          font-size: 0.9375rem !important;
          > div {
            font-size: 0.9375rem !important;
          }
        }
      }
    }
  }
  @media (min-width: 1600px) {
    .budget-data__item,
    .budget-data__item.header {
      .name {
        max-width: 21rem !important;

        > div {
          font-size: 0.75rem; 
        }
      }

      .rest {
        > div {
          font-size: 0.75rem !important;
          min-width: 180px !important;
          > div {
            font-size: 0.75rem !important;
          }
        }
      }
    }

     .budget-data__item {
       .rest {
        > div {
          font-size: 1rem !important;
          > div {
            font-size: 1rem !important;
          }
        }
      }
    }
  }
  @media (min-width: 1920px) {
    .budget-data__item,
    .budget-data__item.header {
      .rest {
        justify-content: space-between;
        padding-right: 0;
      }
    }
  }

  @media (min-width: 2040px) {
    .doubled {
      max-width: 210px;
    }
  }
 
`;

export const PopulationDynamicContainer = styled.div`
  padding-right: 1.875rem;
  padding-left: 1.875rem;

  .dates {
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    margin-bottom: 1.25rem;

    > div {
      display: flex;
      flex: 1;
      justify-content: space-between;
      padding-left: 0.625rem; 
    }
  }

  .population {
    display: flex;
    gap: 0.75rem;
    position: relative;
    margin-bottom: 2.5rem;

    .left,
    .right {
      flex: 0.5;
    }

    .left {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      .upper {
        flex: 0.6;
        background-color: var(--primary-color);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        flex-direction: column;
        gap: 1.5rem;
        text-transform: uppercase;
        padding: 1.5rem;
        height: 12.3125rem;
        border-radius: 0.625rem;

        .total {
          display: flex;
          gap: 0.625rem;
          align-items : baseline;

          > div {
            height: min-content;
          }
        }

        .genders {
          display: flex;
          justify-content: center;
          gap: 3.5rem;

          .gender {
            display: flex;
            align-items: center;
            gap: 0.5rem;
          }

          .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 2.5625rem;
            width: 1.5rem;

            svg {
              min-width: 1.5rem;
              min-height: 2.5625rem;
            }
          }
        }
      }

      .lower {
        flex: 0.4;

        .title {
          background: var(--population-dynamic-bg); 
          border-radius: 0.625rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.5rem 0.75rem;
          text-transform: uppercase;

          &__left {
            display: flex;
            gap: 1rem;
            align-items: center;
          }
        }

        svg path {
          fill: var(--icon-fill);
        }

        .line-chart-container {
          height: 4.6875rem;
        }
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .population__item {
      width: 100%;
      height: 3.625rem;
      border-radius: 0.625rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      text-transform: uppercase;
      .icon {
        max-width: 2rem;
        max-height: 2rem;   

        svg {
          max-width: 2rem;
          max-height: 2rem;
        }
      } 

      &:nth-child(odd) {
        background-color: var(--population-dynamic-bg);
      }

      &:nth-child(even) {
        border: 1px solid var(--population-dynamic-border);
      }

      &__left {
        display: flex;
        gap: 0.625rem;
        align-items: center;
      }

      svg,
      svg path,
      rect,
      g,
      path {
        fill: var(--icon-fill);
      }
    }
  }

  .employment {
    display: flex;
    gap: 0.625rem;
    overflow: hidden;

    &__right {
      flex: 1;

      .chart-container {
        min-height: 100%;
      }
    }

    &__left {
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      gap: 0.625rem;

      > div:first-child {
        width: 15rem;

        height: min-content;
      }
    }

    &__value {
      display: flex;
      gap: 0.625rem;
      align-items: baseline;
    }
  }
`;

export const SERTable = styled.div`
  padding: 1.25rem;
  background-color: #fff;

  table {
    border-collapse: separate;
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    width: 100%;
    border-spacing: 0;
    overflow: hidden;
  }

  .row {
    display: flex;
    font-size: 0.875rem;

    div {
      font-size: 0.875rem;
    }

    div:first-child {
      min-width: 4.71875rem;
      border-right: 1px solid #DBDCE0;
    }

    div:last-child {
      padding-left: 1.25rem;
    }
  }

  thead {
    background: #F6F6F6;
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;

    tr.no-border th {
      border-top: none;
      height: 1.25rem;  
    }

    th {
      border-right: 1px solid #DBDCE0;
      border-bottom: 1px solid #DBDCE0;
      border-top: 1px solid #DBDCE0;
      color: #7C7E7C;
      font-size: 0.875rem;
      font-weight: 400;
      text-align: left;
      padding-left: 0.9375rem;
      padding-right: 0.9375rem;
      height: 2.5rem;

      svg {
        cursor: pointer;
      }

      div:not(.row) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        div:not(.row):first-child {
          flex: 1;
        }
      }
    }

    th.no-border {
      border-bottom: none;
      height: 1.25rem;
    }

    th:first-child {
      border-top-left-radius: 0.3125rem; 
      border-left: 1px solid #DBDCE0;
    }

    th:last-child {
      border-top-right-radius: 0.3125rem; 
    }

    
  }

  tbody {
    tr {
      td {
        color: var(--primary-color);
        font-size: 0.875rem;
        font-weight: 600;
        border-right: 1px solid #DBDCE0;
        border-bottom: 1px solid #DBDCE0;
        padding-left: 0.9375rem;
        vertical-align: middle;
      }

      td:first-child {
        height: 2.5rem;
        max-height: 2.5rem; 
        overflow: hidden; 
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid #DBDCE0;
        border-bottom: 1px solid #DBDCE0;
        border-right: 1px solid #DBDCE0;
        min-width: 2.5rem;
        padding: 0;

        svg {
          height: 1.125rem;
          width: auto;
        }
      }

      td.ifoPlace {
        min-width: 4.71875rem;
      }
    }

   
    
  }
`